import { createPath, useLocation } from '@remix-run/react';
import * as Sentry from '@sentry/browser';
import {
  initializeAnalytics,
  setGlobalProperties,
  setUserProperties,
  trackWebVitalMetric,
  useAnalyticsStore
} from '@tectonic/analytics';
import { loadScript, usePrevious } from '@tectonic/utils';
import { isEmpty, isNil } from 'lodash-es';
import { useEffect } from 'react';
import { onCLS, onFCP, onINP, onLCP, onTTFB } from 'web-vitals';
import { usePageDurationTracker } from './usePageDurationTracker';
import { usePageViewTracker } from './usePageViewTracker';
import useSessionDurationTracker from './useSessionDurationTracker';

import type { MixpanelConfig } from '@tectonic/analytics';
import type { SessionInfo, User } from '@tectonic/types';

// Keeps the store for analytics in sync with ui state.
const useAnalyticsStoreEffect = () => {
  const setParams = useAnalyticsStore((state) => state.setParams);
  const location = useLocation();

  // There's no way to get previous url reliable. We use ref to remember previous
  // location and construct it manually when it is available.
  // https://stackoverflow.com/questions/3528324/how-to-get-the-previous-url-in-javascript
  const prevLocationRef = usePrevious<Location>(null);

  useEffect(() => {
    const previousUrl = prevLocationRef.current
      ? createPath(prevLocationRef.current ?? {})
      : null;
    // @ts-ignore
    prevLocationRef.current = location;
    // @ts-ignore
    setParams({ previousUrl });
  }, [prevLocationRef, location, setParams]);
};

const useAnalyticsInitEffect = (
  env: Record<string, string>,
  ttTester: boolean,
  sessionInfo: SessionInfo
) => {
  const {
    MIXPANEL_PROJECT_TOKEN,
    MIXPANEL_PROXY_DOMAIN,
    MIXPANEL_DEBUG_MODE,
    NODE_ENV,
  } = env;

  const {
    session
  } = sessionInfo ?? {}

  useEffect(() => {
    const mixpanel: MixpanelConfig = {
      token: MIXPANEL_PROJECT_TOKEN,
      options: {
        ignore_dnt: true,
        persistence: 'localStorage',
        debug: NODE_ENV !== 'production' || MIXPANEL_DEBUG_MODE === 'true',
        api_host: MIXPANEL_PROXY_DOMAIN,
      },
    };
    const { id, ...rest } = session ?? {};
    initializeAnalytics({ mixpanel }, ttTester, id, rest);
  }, [
    MIXPANEL_PROJECT_TOKEN,
    MIXPANEL_PROXY_DOMAIN,
    MIXPANEL_DEBUG_MODE,
    NODE_ENV,
    ttTester,
    session
  ]);
};

// We have custom implementation of tracking. Therefore, we need to set, reset
// and alias user as per our backend implementation.
// 1. `ttUserId`
//    - We add `ttUserId` as current user id to identity a user for an event.
//    - It is always in sync with the user available in context.
// 2. `identify`
//    - We identify user only when the user signs in or signs up.
// 3. `aliases`
//    - Users are aliased when they login. We don't aliases a user when they sign
//      up cause the user id doesn't change.
const useAnalyticsUserEffect = (currentUser: User) => {
  const { externalUserId: userId, simpleId, hashedInfo } = currentUser;
  const isAnonymous =
    isNil(currentUser?.isAnonymous) || currentUser.isAnonymous === true;

  // Keep `ttUserId` up to date.
  useEffect(() => {

    const ttUserEmail256Hash = hashedInfo?.email?.sha256 ?? null;
    const ttUserPhone256Hash = hashedInfo?.phone?.sha256 ?? null;
    const ttUserEmailMD5Hash = hashedInfo?.email?.md5 ?? null;
    const ttUserPhoneMD5Hash = hashedInfo?.phone?.md5 ?? null;

    setGlobalProperties({
      ttUserId: userId,
      ttSourceUserId: simpleId,
      ttUserEmailSHA256: ttUserEmail256Hash,
      ttUserPhoneSHA256: ttUserPhone256Hash,
      ttUserEmailMD5: ttUserEmailMD5Hash,
      ttUserPhoneMD5: ttUserPhoneMD5Hash,
    });
  }, [userId, simpleId, hashedInfo]);

  // Keep `isAnonymous` up to date.
  useEffect(() => {
    Sentry.setUser({ id: userId });
    setUserProperties({ isAnonymous });
    setGlobalProperties({ isAnonymous });
  }, [isAnonymous, userId]);
};

const useGtagEffect = (env: Record<string, string>) => {
  const { GTAG_HOST, GTAG_ID, GTAG_JS_URL, ORGANIZATION } = env;

  useEffect(() => {
    globalThis.dataLayer = globalThis.dataLayer ?? [];
    globalThis.gtag = function gtagFn() {
      // eslint-disable-next-line prefer-rest-params
      globalThis.dataLayer.push(arguments);
    };
    globalThis.gtag('consent', 'default', {
      'ad_storage': 'granted',
      'ad_user_data': 'granted',
      'ad_personalization': 'granted',
      'analytics_storage': 'granted'
    });
    loadScript(GTAG_JS_URL);
    globalThis.gtag('js', new Date());
    globalThis.gtag('config', GTAG_ID, {
      sever_container_url: `https://${GTAG_HOST}`,
      tt_event_source: 'HERMES_CLI',
      tt_org: ORGANIZATION,
      tt_ga_tag: GTAG_ID,
    });
  }, [GTAG_HOST, GTAG_ID, GTAG_JS_URL, ORGANIZATION]);
};


const useRUMEffect = () => {
  useEffect(() => {
    try {
      onCLS(trackWebVitalMetric)
      onTTFB(trackWebVitalMetric)
      onINP(trackWebVitalMetric)
      onFCP(trackWebVitalMetric)
      onLCP(trackWebVitalMetric)
    } catch (err) {
      // Log errors in future if we want to track
    }
  }, []);
};

const useGtmEffect = (env: Record<string, string>) => {
  const { GTM_ID, GTAG_HOST, ORGANIZATION } = env;
  useEffect(() => {
    if (isEmpty(GTM_ID)) {
      return
    }
    const GTM_JS_URL = `https://${GTAG_HOST}/gtm.js?id=${GTM_ID}&l=dataLayerClientSide`
    globalThis.dataLayerClientSide = globalThis.dataLayerClientSide ?? [];
    globalThis.clientSideGtag = function clientSideGtagFn() {
      // eslint-disable-next-line prefer-rest-params
      globalThis.dataLayerClientSide.push(arguments);
    };
    globalThis.clientSideGtag('consent', 'default', {
      'ad_storage': 'granted',
      'ad_user_data': 'granted',
      'ad_personalization': 'granted',
      'analytics_storage': 'granted'
    });
    loadScript(GTM_JS_URL);
    globalThis.dataLayerClientSide.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    })
  }, [GTAG_HOST, GTM_ID, ORGANIZATION]);
};

const useClartiyEffect = (
  env: Record<string, string>,
  user: User,
  sessionInfo: SessionInfo
) => {
  const { CLARITY_TOKEN } = env;
  const { simpleId, isAnonymous, externalUserId } = user;
  const { id: sessionId, landingUrl } = sessionInfo?.session ?? {};

  useEffect(() => {
    if (isEmpty(CLARITY_TOKEN)) {
      return
    }

    try {
      // eslint-disable-next-line func-names
      globalThis.clarity = globalThis.clarity ?? function () {
        // eslint-disable-next-line prefer-rest-params
        (globalThis.clarity.q = globalThis.clarity.q || []).push(arguments);
      };
      const CLARITY_URL = `https://www.clarity.ms/tag/${CLARITY_TOKEN}`
      loadScript(CLARITY_URL);
      // TODO set the below based on user consent
      globalThis.clarity("consent")
      globalThis.clarity("identify", externalUserId)
      globalThis.clarity("set", "ttUserId", externalUserId);

      globalThis.clarity("set", "ttSessionType", isAnonymous ? "ANONYMOUS" : "LOGGED_IN");
      if (!isEmpty(simpleId)) {
        globalThis.clarity("set", "ttSourceUserId", simpleId);
      }
      if (!isEmpty(sessionId)) {
        globalThis.clarity("set", "ttClientSessionId", sessionId);
      }
      if (!isEmpty(landingUrl)) {
        globalThis.clarity("set", "ttClientSessionLandingUrl", landingUrl);
      }
    } catch {
      // log to sentry / collect metrics
    }

  }, [
    CLARITY_TOKEN,
    externalUserId,
    isAnonymous,
    simpleId,
    sessionId,
    landingUrl
  ])
}

// TODO for testing storing the data in localstorage, will move to using react components + state, env, etc once finalized
// const useClientFPEffect = (env: Record<string, string>) => {
//   const { ENABLE_FP_PRO, NODE_ENV, FP_PRO_KEY, FP_PRO_REGION } = env;

//   useEffect(() => {
//     (async () => {
//       if (NODE_ENV !== 'production' || ENABLE_FP_PRO !== 'true' || isEmpty(FP_PRO_KEY) || isEmpty(FP_PRO_REGION)) {
//         return
//       }
//       try {
//         const fp = await initializeFingerprintProProxy(
//           FP_PRO_KEY,
//           FP_PRO_REGION
//         );
//         const fpData = await fp.get({ extendedResult: true });
//         LocalStorage.setItem("fingerPrintPro", fpData)
//       } catch (err) {
//         // console.error("[FP_PRO_ERR]", err)
//         // log to sentry
//       }
//     })();
//   }, [ENABLE_FP_PRO, NODE_ENV]);

//   useEffect(() => {
//     (async () => {
//       try {
//         const fp = await initializeFingerprintV3();
//         const fpData = await fp.get();
//         LocalStorage.setItem('fingerPrintV3', fpData);
//       } catch (err) {
//         // log to sentry
//       }
//     })();
//   }, []);

//   useEffect(() => {
//     (async () => {
//       try {
//         const fp = await generateV0Fp();
//         LocalStorage.setItem('ttClientFpV0', fp);
//       } catch (err) {
//         // log to sentry
//       }
//     })();
//   }, []);
// };

// const useCerberusEffect = (
//   env: Record<string, string>,
//   user: User,
//   sessionInfo: SessionInfo,
//   ttTester: boolean
// ) => {
//   const { ORGANIZATION, NODE_ENV } = env;
//   useEffect(() => {
//     (async () => {
//       if (NODE_ENV !== 'production') {
//         return
//       }
//       try {
//         // TODO move below to config
//         const cerberusResp = await fetch(
//           `https://cerberus.tectonic.so/v0/org/${ORGANIZATION}/elrond`,
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//             },
//             body: JSON.stringify({
//               userId: user.externalUserId,
//               session: sessionInfo.session,
//               org: ORGANIZATION,
//               ttTester,
//             }),
//           }
//         );
//         const cerberusData = await cerberusResp.json();
//         LocalStorage.setItem("tt_cerberus_data", JSON.stringify(cerberusData));
//       } catch (err) {
//         Logger.error("[CERBERUS_ERR]", err)
//         // log to sentry
//       }
//     })();
//   }, [
//     ORGANIZATION,
//     NODE_ENV,
//     ttTester,
//     user.externalUserId,
//     sessionInfo.session
//   ]);
// }

const useAnalyticsEffect = ({
  env,
  currentUser,
  sessionInfo,
  ttTester,
}: {
  env: Record<string, string>;
  currentUser: unknown;
  sessionInfo: SessionInfo;
  ttTester: boolean;
}) => {
  useAnalyticsStoreEffect();
  useGtagEffect(env);
  useGtmEffect(env);
  useAnalyticsInitEffect(env, ttTester, sessionInfo);
  useAnalyticsUserEffect(currentUser as User);
  useRUMEffect();
  // useClientFPEffect(env);
  // useCerberusEffect(env, currentUser as User, sessionInfo, ttTester);
  useClartiyEffect(env, currentUser as User, sessionInfo);
  usePageViewTracker();
  usePageDurationTracker();
  useSessionDurationTracker();
};

export { useAnalyticsEffect };
