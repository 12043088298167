/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-danger */
import { json } from '@remix-run/node';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from '@remix-run/react';
import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ElemasonRemixProvider } from '@tectonic/elemason-remix';
import { ThemeProvider } from '@tectonic/uikit';
import {
  populate,
  populateEnvs,
  isMobile as queryIsMobile,
} from '@tectonic/utils';
import { setValgrindHostInfo } from '@tectonic/valgrind';
import axios from 'axios';
import { createHash } from 'crypto';
import { isEmpty } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useDehydratedState } from 'use-dehydrated-state';
import FingerprintsDisplay from './components/Fingerprints';
import Transition from './components/Transition';
import { RootProvider } from './contexts/RootContext';
import useAppLaunchEffect from './hooks/useAppLaunchEffect';

import type { AppLoadContext, LoaderFunctionArgs } from '@remix-run/node';
import type { Routes } from '@tectonic/elemason-remix';
import type { Color, ElemasonWidget, SessionInfo, User, UserHashedInfo } from '@tectonic/types';
import type { ComponentType, FC, ReactNode } from 'react';

interface AppProps {
  routes: Routes;
  title?: string;
  cssHref?: string;
  svgHref?: string;
  description?: string;
  colors: Record<Color, string>;
  // extra children to be added to the head
  head?: ReactNode;
  body?: ReactNode;
  widgets?: Record<
    string,
    ComponentType<{ widget: ElemasonWidget<unknown, unknown, unknown> }>
  >;
}

const minifyCSS = (css: string) => {
  // Remove comments
  css = css.replace(/\/\*[\s\S]*?\*\//g, '');

  // Remove newline characters
  css = css.replace(/\n/g, '');

  // Remove whitespace before and after colons
  css = css.replace(/\s*:\s*/g, ':');

  // Remove whitespace before and after semicolons
  css = css.replace(/\s*;\s*/g, ';');

  // Remove whitespace before and after commas
  css = css.replace(/\s*,\s*/g, ',');

  // Remove whitespace before and after curly braces
  css = css.replace(/\s*{\s*/g, '{');
  css = css.replace(/\s*}\s*/g, '}');

  // Remove whitespace before and after parentheses
  css = css.replace(/\s*\(\s*/g, '(');
  css = css.replace(/\s*\)\s*/g, ')');

  return css;
};

const getBrowserEnv = (context: AppLoadContext): Record<string, string> => {
  const env = context.browserEnvs as Record<string, string>;
  const { GTAG_HOST, GTAG_ID, ORGANIZATION, API_HOST, GTM_ID } = env;
  const placeholders = {
    organization: ORGANIZATION,
    gtmHost: GTAG_HOST,
    gtagId: GTAG_ID,
    apiHost: API_HOST,
  };

  return {
    ORGANIZATION: env.ORGANIZATION,
    NODE_ENV: process.env.NODE_ENV,

    MIXPANEL_PROJECT_TOKEN: env.MIXPANEL_PROJECT_TOKEN,
    MIXPANEL_PROXY_DOMAIN: env.MIXPANEL_PROXY_DOMAIN,
    ENABLE_FP_PRO: env.ENABLE_FP_PRO,
    FP_PRO_KEY: env.FP_PRO_KEY,
    FP_PRO_REGION: env.FP_PRO_REGION,

    GTAG_HOST,
    GTAG_ID,
    GTM_ID,
    GTAG_JS_URL: populate(env.GTAG_JS_URL, placeholders),

    FIREBASE_APP_ID: env.FIREBASE_APP_ID,
    FIREBASE_API_KEY: env.FIREBASE_API_KEY,
    FIREBASE_PROJECT_ID: env.FIREBASE_PROJECT_ID,
    FIREBASE_AUTH_DOMAIN: env.FIREBASE_AUTH_DOMAIN,
    FIREBASE_STORAGE_BUCKET: env.FIREBASE_STORAGE_BUCKET,
    FIREBASE_MEASUREMENT_ID: env.FIREBASE_MEASUREMENT_ID,
    FIREBASE_MESSAGING_SENDER_ID: env.FIREBASE_MESSAGING_SENDER_ID,

    DAM_URL: populate(env.DAM_URL, placeholders),
    API_END_POINT_V2: populate(env.API_END_POINT_V2, placeholders),
    ROOT_DOMAIN: env.ROOT_DOMAIN,
    SENTRY_DSN: env.SENTRY_DSN,
    MIXPANEL_DEBUG_MODE: env.MIXPANEL_DEBUG_MODE,

    CLARITY_TOKEN: env.CLARITY_TOKEN
  };
};

let cachedCss = '';


const getCurrentUserFromContext = (context: AppLoadContext) => {
  const user = (context.auth as Record<string, unknown>).currentUser as User
  const { email, phone } = user

  const hashedInfo: UserHashedInfo = {
    email: null,
    phone: null
  }

  if (!isEmpty(email)) {
    hashedInfo.email = {
      sha256: createHash('sha256').update(email).digest('hex'),
      md5: createHash('md5').update(email).digest('hex')
    }
  }

  if (!isEmpty(phone)) {
    hashedInfo.phone = {
      sha256: createHash('sha256').update(phone!).digest('hex'),
      md5: createHash('md5').update(phone!).digest('hex')
    }
  }

  user.hashedInfo = hashedInfo

  return user
}

const loaderGen =
  (mainCss: string) =>
    async ({ context, request }: LoaderFunctionArgs) => {
      populateEnvs(process.env);

      const env = getBrowserEnv(context);
      const isMobile = queryIsMobile(request.headers.get('user-agent'));

      const css =
        cachedCss ||
        ((await axios.get(`${new URL(request.url).origin}${mainCss}`, {
          headers: {
            'X-Tectonic-Valgrind-Force-Self': 'true'
          }
        }))
          .data as string);

      cachedCss = css;

      const experiments = {
        athenaVariantInfo: context.athenaVariantInfo,
        athenaRootVariantInfo: context.athenaRootVariantInfo,
      }

      return json({
        env,
        isMobile,
        experiments,
        css: minifyCSS(css),
        serverTime: Date.now(),
        currentUser: (context.auth as Record<string, unknown>)
          .currentUser as User,
        sessionInfo: context.sessionInfo as SessionInfo,
        ttTester: context.ttTester,
        valgrindCurrentVersion: context.valgrindCurrentVersion,
      });
    };

const loader = async ({ context, request }: LoaderFunctionArgs) => {
  populateEnvs(process.env);

  const env = getBrowserEnv(context);
  const isMobile = queryIsMobile(request.headers.get('user-agent'));
  const experiments = {
    athenaVariantInfo: context.athenaVariantInfo,
    athenaRootVariantInfo: context.athenaRootVariantInfo,
  } as any;

  return json({
    env,
    isMobile,
    experiments,
    css: '',
    serverTime: Date.now(),
    currentUser: getCurrentUserFromContext(context),
    sessionInfo: context.sessionInfo as SessionInfo,
    ttTester: context.ttTester as boolean,
    valgrindCurrentVersion: context.valgrindCurrentVersion,
  });
};

type RootLoaderType = Awaited<
  ReturnType<Awaited<ReturnType<typeof loader>>['json']>
>;

// TODO: Delete this package and make it part of the application code itself.
// - We need to setup analytics. Analytics requires it's own store. If we keep core
// as a separate we'll have to add elemason as dependency to this project and import
// functions like toAnalyticsPayload etc.
// - we need route handle data so that we can pass arbitrary data to component
// eg. analytics requires screen name. Presently, we dump screen name to analytic
// store.
// - Currently, remix is not part of the elemason. Because of that when we a user
// navigates from one screen to another, entire page is reloaded since we are
// using `window.location.href=something`. When we use useNavigation from
// remix, it doesn't reload the entire page.

const App: FC<AppProps> = ({
  colors,
  routes,
  title,
  cssHref,
  svgHref,
  description,
  head,
  widgets,
  body,
}) => {
  const dehydratedState = useDehydratedState();
  const [queryClient] = useState(() => new QueryClient());
  const {
    env,
    currentUser,
    ttTester,
    sessionInfo,
    isMobile,
    serverTime,
    css,
    experiments,
    valgrindCurrentVersion,
  } = useLoaderData<RootLoaderType>();

  useEffect(() => {
    globalThis.timeDrift = Date.now() - serverTime;
  }, []);

  /*
    Specifically for those clients who need to do A/B between juiz and elemason,
    we set the host to maintain stickyness for the user, in router we read this 
    cookie to determine which host to redirect to, needs to be client side cookie 
    so that we can set it on the root domain
  */
  useEffect(() => {
    // @ts-ignore
    setValgrindHostInfo(valgrindCurrentVersion ?? 1);
  }, [valgrindCurrentVersion]);

  useAppLaunchEffect({
    env,
    currentUser,
    isMobile,
    sessionInfo,
    serverTime,
    ttTester,
  });

  // const memoOIB = useMemo(() => <OIB />, []);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <link rel="preconnect" href="https://cdn.shopify.com" />
        <link rel="preconnect" href={env.DAM_URL} />
        <link rel="preconnect" href="https://c.clarity.ms" />
        <link rel="preconnect" href="https://c.bing.com" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5"
        />
        {title ? (
          <>
            <title>{title}</title>
            <meta property="og:title" content={title} />
          </>
        ) : null}

        {description ? (
          <>
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
          </>
        ) : null}
        {css ? (
          <style suppressHydrationWarning>{css}</style>
        ) : (
          cssHref && (
            <link
              suppressHydrationWarning
              // @ts-ignore
              fetchpriority="high"
              rel="stylesheet"
              href={cssHref}
              as="style"
            />
          )
        )}
        <Meta />
        <Links />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.env = JSON.parse('${JSON.stringify(
              env
            )}');let lastScrollY = 0;
            let scheduledAnimationFrame;
            function readAndUpdatePage(){
              document?.body?.style.setProperty("--scroll-y", "" + lastScrollY);
            }
            function onScroll () {
              lastScrollY = window.scrollY;
              scheduledAnimationFrame = true;
              requestAnimationFrame(readAndUpdatePage);
            }
            window.window.addEventListener('scroll', onScroll);`,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
            document.cookie = \`__tt_sanity=\${Date.now()}; path=/;\`
            window.addEventListener('pageshow', (event) => {
              if (event.persisted && !document.cookie.match(/__tt_sanity/)) {
                window.location.reload();
              }
            })`,
          }}
        />
        {head ?? null}
      </head>
      {/* @ts-ignore */}
      <body className="transition-all" style={{ '--scroll-y': 0 }}>
        {ttTester ? (
          <div className=" pointer-events-none fixed bottom-16 end-4 right-4 z-50 flex h-16 w-16 animate-pulse items-center justify-center rounded-full border-4 border-red-100 bg-orange-600">
            <p className="headline7">🤖</p>
          </div>
        ) : null}
        {/* {memoOIB} */}
        <FingerprintsDisplay />
        <RootProvider value={{ env, currentUser, isMobile }}>
          <Transition />
          <ThemeProvider
            value={{
              colors,
              icon: {
                spinner: 'outline-spinner',
                sprite: svgHref ?? '/main.svg',
              },
            }}
          >
            <ElemasonRemixProvider
              value={{
                user: currentUser as User,
                env,
                routes,
                widgets,
                experiments,
              }}
            >
              <QueryClientProvider client={queryClient}>
                <HydrationBoundary state={dehydratedState}>
                  <Outlet />
                </HydrationBoundary>
              </QueryClientProvider>
            </ElemasonRemixProvider>
          </ThemeProvider>
        </RootProvider>
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        {body}
      </body>
    </html>
  );
};

export { loaderGen, App as RootApp, loader as rootLoader };
export type { RootLoaderType };

