import { Logger } from '@tectonic/logger';
import { isNil, takeRight } from 'lodash-es';

const getRootDomain = () => {
  const url = new URL(window.location.href);
  if (url.hostname.endsWith('.localhost')) return url.hostname;
  return takeRight(url.hostname.split('.'), 2).join('.');
};

const setCookie = (cname: string, cvalue: string, expiresInDays?: number) => {
  let expires = '';
  const rootDomain = getRootDomain();

  if (!isNil(expiresInDays)) {
    const d = new Date();
    d.setTime(d.getTime() + expiresInDays * 24 * 60 * 60 * 1000);
    expires = `expires=${d.toUTCString()};`;
  }

  const domain = `domain=.${rootDomain};`;

  document.cookie = `${cname}=${encodeURIComponent(cvalue)}; ${expires} ${domain} SameSite=None; Secure; path=/`;
};

const setValgrindHostInfo = (version: number) => {
  try {
    // eslint-disable-next-line prefer-destructuring
    const host = window.location.host;
    setCookie('__tt__valgrind', btoa(JSON.stringify({ host, version })), 365);
  } catch (error) {
    Logger.error(error, "[setValgrindHostInfoErr]")
  }
};

export { setValgrindHostInfo };
